<template>
  <v-menu
    v-model="menuVisible"
    bottom
    offset-y
    transition="slide-y-transition"
    style="z-index: 998"
    nudge-width="200"
    @input="handleMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="secondary"
        offset-y="20"
        offset-x="15"
        icon="settings"
      >
        <v-avatar size="45" v-bind="attrs" v-on="on">
          <v-img v-if="photo" :src="photo" />
        </v-avatar>
      </v-badge>
    </template>
    <v-list nav>
      <v-list-item class="border-bottom-grey">
        <v-list-item-content>
          <div>
            <span class="subtitle-2 mr-3 secondary--text font-weight-bold">
              {{ userFirstName }}
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>
      <template v-for="menu in menus">
        <v-list-item
          :id="menu.id"
          :key="menu.toName"
          :to="{ name: menu.toName }"
        >
          <v-list-item-avatar>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title style="min-width: 80px">
            {{ $t(menu.text) }}
          </v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item
        v-if="userRoles.length > 1"
        id="change_profile"
        @click="changeProfile"
      >
        <v-list-item-avatar>
          <v-icon>mdi-account-convert</v-icon>
        </v-list-item-avatar>
        <v-list-item-content> Alterar perfil </v-list-item-content>
      </v-list-item>
      <v-list-item id="exit_app" @click="logout">
        <v-list-item-avatar>
          <v-icon>exit_to_app</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t("menu.exit") }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import userMenus from "@/router/user";
export default {
  name: "UserMenu",
  data: () => ({
    menuVisible: false,
    isTourActive: false,
  }),
  computed: {
    userFirstName() {
      return this.$store.getters["login/userFirstName"];
    },
    userRoles() {
      return this.$store.getters["login/roles"];
    },
    photo() {
      return (
        this.$store.getters["login/photo"] || require("@/assets/no-photo.svg")
      );
    },
    menus() {
      const selectedRole = this.$store.getters["login/activeRole"];

      return userMenus
        .filter((menu) => menu.visible)
        .filter((menu) =>
          menu.meta?.role ? menu.meta.role === selectedRole : true
        )
        .map((menu) => ({
          toName: menu.name,
          text: menu.meta.text,
          icon: menu.icon,
          id: menu.meta.id,
        }));
    },
  },

  methods: {
    updateTherapistOnboarding() {
      const onboarding = this.$store.getters["login/onboarding"];
      this.$store.dispatch("login/updateOnboarding", {
        ...onboarding,
        userMenuTherapist: true,
      });
    },

    updatePatientOnboarding() {
      const onboarding = this.$store.getters["login/onboarding"];
      this.$store.dispatch("login/updateOnboarding", {
        ...onboarding,
        userMenuPatient: true,
      });
    },
    logout() {
      this.drawer = false;
      this.$store.dispatch("login/logout");
    },
    async handleOnboarding() {
      const onDeselected = (element) => {
        const isActivated = this.$vueJsDriver.isActivated;
        if (isActivated) {
          setTimeout(() => {
            this.showMenu();
          }, 1);
        } else {
          this.isTourActive = false;
          setTimeout(() => {
            this.showMenu();
          }, 1);
        }
      };
      const position = window.innerWidth <= 600 ? "bottom" : "left";
      const therapistSteps = [
        {
          element: "#my_account",
          popover: {
            title: `Meus dados`,
            description:
              "Aqui você pode visualizar e editar suas informações pessoais.",
            position: position,
          },
          onDeselected: onDeselected,
        },
        {
          element: "#profile",
          popover: {
            title: "Meu perfil",
            description:
              "Aqui você define suas especialidades e informações profissionais.",
            position: position,
          },
          onDeselected: onDeselected,
        },

        {
          element: "#my_subscription",
          popover: {
            title: "Assinaturas",
            description:
              "Aqui você pode visualizar e alterar suas assinaturas ativas.",
            position: position,
          },
          onDeselected: onDeselected,
        },
        {
          element: "#change_profile",
          popover: {
            title: "Alterar perfil",
            description:
              "Aqui você pode alternar entre os perfis de terapeuta e paciente.",
            position: position,
          },
          onDeselected: onDeselected,
        },
        {
          element: "#exit_app",
          popover: {
            title: "Sair",
            description: "Clique aqui para sair do sistema.",
            position: position,
          },
          onDeselected: onDeselected,
          onNext: () => {
            this.updateTherapistOnboarding();
          },
        },
      ];

      const patientSteps = [
        {
          element: "#my_account",
          popover: {
            title: `Meus dados`,
            description:
              "Aqui você pode visualizar e editar suas informações pessoais.",
            position: position,
          },
          onDeselected: onDeselected,
        },
        {
          element: "#change_profile",
          popover: {
            title: "Alterar perfil",
            description:
              "Aqui você pode alternar entre os perfis de terapeuta e paciente.",
            position: position,
          },
          onDeselected: onDeselected,
        },
        {
          element: "#exit_app",
          popover: {
            title: "Sair",
            description: "Clique aqui para sair do sistema.",
            position: position,
          },
          onDeselected: onDeselected,
          onNext: () => {
            this.updatePatientOnboarding();
            this.isTourActive = false;
          },
        },
      ];
      const selectedRole = this.$store.getters["login/activeRole"];
      const onboarding = this.$store.getters["login/onboarding"];
      const steps =
        selectedRole === "therapist"
          ? therapistSteps
          : selectedRole === "patient"
          ? patientSteps
          : [];

      if (
        (selectedRole === "therapist" &&
          (!onboarding || !onboarding.userMenuTherapist)) ||
        (selectedRole === "patient" &&
          (!onboarding || !onboarding.userMenuPatient))
      ) {
        await this.showDriver(steps);
        this.isTourActive = true;
      }
    },
    showDriver(steps) {
      this.$vueJsDriver.showDriver(steps);
    },
    showMenu() {
      this.menuVisible = true;
    },
    handleMenu(val) {
      if (val) {
        console.log("Menu is opened");
        setTimeout(() => {
          this.handleOnboarding();
        }, 500);
      } else {
        if (this.isTourActive) {
          setTimeout(() => {
            this.showMenu();
          }, 10);
        } else {
          this.menuVisible = false;
        }
        // Only close the menu if the tour is not active
        console.log("Menu is closed");
      }
    },
    changeProfile() {
      this.$store.dispatch("login/changeActiveRole", null);
      this.$router.replace({ name: "home" });
    },
  },
};
</script>

<style scoped>
/* Customize fonts for Driver.js */

.driver-popover-description {
  font-family: "Monserrat", sans-serif;
  font-weight: 300;
}

.driver-button {
  font-family: "Monserrat", sans-serif;
  font-weight: 400;
}
</style>
